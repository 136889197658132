*::-moz-selection {
  background: $mainColor;
  color: #fff;
}

*::selection {
  background: $mainColor;
  color: #fff;
}
html {
  font-size: 62.5%;
  @media #{$iphone5} {
    font-size: 2.66667vw;
  }
}
body {
  font-family: "游明朝", "Yu Mincho", YuMincho, "游明朝体", "YuMincho", 'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro','Noto Serif JP', serif;
  font-size: $fontsize;
  font-weight: 500;
  color: $fontColor;
  line-height: 1;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: subpixel-antialiased;
  // -moz-osx-font-smoothing: auto;
  -webkit-text-size-adjust:100%;
  @media #{$breakpoint} {
    min-width: 0;
    letter-spacing: 0em;
    font-size: $fontsizeSp;
  }
}
a {
  text-decoration: none;
  color: $fontColor;
}
img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
}
button {
  border: none;
  padding: 0;
  background: none;
}
