@charset "UTF-8";

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　PATH
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// usage: #{$imgPath}
$imgPath: "../img/";

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　COLOR
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$fontColor: #000;
$mainColor: #000;
$keyColor: #000;


// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　FONT
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

$fontsize: 1.6rem;
$fontsizeSp: 1.4rem;


$fontHina: 'Hina Mincho', serif;


// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　Media Query
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// $columnwidth__01: 1240px;
// $columnwidth__02: 1020px;

$wraper: 100%;

$breakpoint: "screen and (max-width: 48em)";
$tablet: "(min-width: 48em) and (max-width: 76.875em)";
$ie11: "all and (-ms-high-contrast:none)";
$iphone5: "screen and (max-width: 20em)";
