@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, input, button, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

sup {
  font-size: 70%;
  vertical-align: top;
  position: relative;
  top: -0.1em; }

sub {
  font-size: 70%;
  vertical-align: bottom;
  position: relative; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

*::-moz-selection {
  background: #000;
  color: #fff; }

*::selection {
  background: #000;
  color: #fff; }

html {
  font-size: 62.5%; }
  @media screen and (max-width: 20em) {
    html {
      font-size: 2.66667vw; } }

body {
  font-family: "游明朝", "Yu Mincho", YuMincho, "游明朝体", "YuMincho", 'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro','Noto Serif JP', serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #000;
  line-height: 1;
  -webkit-text-size-adjust: 100%; }
  @media screen and (max-width: 48em) {
    body {
      min-width: 0;
      letter-spacing: 0em;
      font-size: 1.4rem; } }

a {
  text-decoration: none;
  color: #000; }

img {
  max-width: 100%;
  -webkit-backface-visibility: hidden; }

button {
  border: none;
  padding: 0;
  background: none; }

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: 200;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@2.0.0/dist/fonts/YakuHanJP/YakuHanJP-Light.eot");
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@2.0.0/dist/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@2.0.0/dist/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
  font-display: swap; }
